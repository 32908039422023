<template>
  <div class="cardTodayClass" @click.prevent="toDetails(item.id)">
    <div
      class="d-flex flex-column head"
      :style="{
        background: mapel.cover ? `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('${config.apiUrl}${mapel.cover}')` : `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/resources/images/covers/${mapel.nama}/cover.png')`,
        backgroundSize: '100% 100%',
      }"
    >
      <div class="card-header card-header-flex border-bottom-0">
        <div class="d-flex flex-column justify-content-center">
          <div class="cardCover">
            <h2>{{ item.slot.mata_pelajaran.nama }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="card cardContent card-borderless">
      <div class="card-body d-flex flex-column px-4">
        <div class="d-flex flex-nowrap align-items-center pb-3">
          <div class="mr-auto">
            <div
              class="text-guru font-weight-bold font-size-18 text-dark"
              style
            >
              {{ item.slot.guru.nama }}
            </div>
          </div>
          <div :style="{ color: iconState.primary }">
            {{
              isIconState(
                item.zoom_setting,
                item.slot.model_jadwal.jam_mulai,
                item.slot.model_jadwal.durasi,
                item.slot.jam_pelajaran
              ).textNow
            }}
            {{ iconState.textTime }}
            <div
              :style="{
                borderRadius: '4px',
                padding: '10px',
                background: iconState.bg,
                display: iconState.icon ? 'inline' : 'none',
              }"
            >
              <a-icon v-if="iconState.icon" :type="iconState.icon" />
              <span>&nbsp;{{ iconState.textIcon }}</span>
            </div>
          </div>
        </div>
        <p class="topik font-weight-bold">
          Week {{ item.week.week }} | {{ item.topik ? item.topik.nama : "-" }}
        </p>
        <p class="sub-topik">{{ item.topik ? item.topik.subtopik : "-" }}</p>
        <div
          class="
            text-primary
            font-size-16
            mt-auto
            d-flex
            flex-nowrap
            align-items-center
          "
        >
          <a-icon
            v-if="item.tipe_assignment == 'File Upload'"
            type="paper-clip"
            style="font-size: 20px"
          />
          <a-icon
            v-else-if="item.tipe_assignment == 'Essay'"
            type="align-left"
            style="font-size: 20px"
          />
          <a-icon
            v-else-if="item.tipe_assignment == 'Multiple Upload'"
            type="unordered-list"
            style="font-size: 20px"
          />
          <a-icon
            v-else
            type="minus-circle"
            style="font-size: 20px; color: gray"
          />
          <span
            class="ml-2"
            :style="{ color: !item.tipe_assignment ? 'gray' : '' }"
            >{{
              !item.tipe_assignment ? "No Assignment" : item.tipe_assignment
            }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import config from '@/config'
export default {
  props: ['item'],
  data() {
    return {
      // Gray State
      iconState: {
        primary: '#7A8090',
        bg: '#F0F1F3',
        textIcon: '',
        textTime: 'Done',
        textNow: '',
        icon: '',
      },
      config,
    }
  },
  methods: {
    toDetails(id) {
      this.$router.push({ name: 'Detail Schedule Student', params: { id } })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Schedule Student'],
      })
    },
    isIconState(zoomSetting, jamMulai, durasi, jamPelajaran) {
      const jamMulaiFormat = moment(jamMulai, 'HH:mm').format('HH:mm')
      const jamMulaiAddDurasiFormat = moment(jamMulai, 'HH:mm').add(durasi * jamPelajaran, 'minutes').format('HH:mm')
      const jamSekarangFormat = moment().format('HH:mm')
      const checkIsAfter = moment(jamMulaiFormat, 'HH:mm').isAfter(moment(jamSekarangFormat, 'HH:mm'))
      // console.log(jamSekarangFormat)
      // Blue
      if (checkIsAfter) {
        this.iconState.primary = '#1C56E3'
        this.iconState.bg = '#EDF2FE'
        this.iconState.textTime = jamMulai
      }
      // Red Now
      if (jamSekarangFormat >= jamMulaiFormat && jamSekarangFormat < jamMulaiAddDurasiFormat) {
        this.iconState.primary = '#FF0000'
        this.iconState.bg = '#FFE5E5'
        this.iconState.textTime = jamMulai
        this.iconState.textNow = 'Now -'
      }
      // Zoom setting true
      if (zoomSetting) {
        this.iconState.icon = 'video-camera'
        this.iconState.textIcon = 'Zoom'
      }
      return this.iconState
    },
  },
  computed: {
    mapel() {
      return this.item.slot?.mata_pelajaran || {}
    },
  },
}
</script>
<style scoped>
.head {
  background-size: cover;
  background-position: center center;
  border-radius: 8px 8px 0 0;
  height: 125px;
}

.cardTodayClass {
  width: 95% !important;
  height: 100%;
  max-height: 337px;
  border-radius: 0 0 8px 8px;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.cardTodayClass:hover {
  transform: translate(0, -5px);
  box-shadow: 0px 0px 20px #0419301a;
}

.card-header {
  height: 100%;
}

.cardCover {
  display: flex;
  align-items: center;
}

.cardCover h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: 800;
  text-align: left;
  color: white;
}

.cardContent {
  border-radius: 0 0 8px 8px;
  min-height: 210px;
}

.cardContent .text-guru {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardContent .text-date {
  font-family: "Mukta", "sans-serif";
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

.cardContent .card-body .topik {
  display: block;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.cardContent .card-body .sub-topik {
  display: block;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cardStats {
  font-family: "Mukta", "sans-serif";
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  color: #1b90ff;
  font-size: 20px;
}
.cardStats .icon {
  color: #1b90ff;
  font-size: 20px;
}
</style>
