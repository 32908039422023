var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardTodayClass",on:{"click":function($event){$event.preventDefault();return _vm.toDetails(_vm.item.id)}}},[_c('div',{staticClass:"d-flex flex-column head",style:({
      background: _vm.mapel.cover ? `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('${_vm.config.apiUrl}${_vm.mapel.cover}')` : `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/resources/images/covers/${_vm.mapel.nama}/cover.png')`,
      backgroundSize: '100% 100%',
    })},[_c('div',{staticClass:"card-header card-header-flex border-bottom-0"},[_c('div',{staticClass:"d-flex flex-column justify-content-center"},[_c('div',{staticClass:"cardCover"},[_c('h2',[_vm._v(_vm._s(_vm.item.slot.mata_pelajaran.nama))])])])])]),_c('div',{staticClass:"card cardContent card-borderless"},[_c('div',{staticClass:"card-body d-flex flex-column px-4"},[_c('div',{staticClass:"d-flex flex-nowrap align-items-center pb-3"},[_c('div',{staticClass:"mr-auto"},[_c('div',{staticClass:"text-guru font-weight-bold font-size-18 text-dark"},[_vm._v(" "+_vm._s(_vm.item.slot.guru.nama)+" ")])]),_c('div',{style:({ color: _vm.iconState.primary })},[_vm._v(" "+_vm._s(_vm.isIconState( _vm.item.zoom_setting, _vm.item.slot.model_jadwal.jam_mulai, _vm.item.slot.model_jadwal.durasi, _vm.item.slot.jam_pelajaran ).textNow)+" "+_vm._s(_vm.iconState.textTime)+" "),_c('div',{style:({
              borderRadius: '4px',
              padding: '10px',
              background: _vm.iconState.bg,
              display: _vm.iconState.icon ? 'inline' : 'none',
            })},[(_vm.iconState.icon)?_c('a-icon',{attrs:{"type":_vm.iconState.icon}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.iconState.textIcon))])],1)])]),_c('p',{staticClass:"topik font-weight-bold"},[_vm._v(" Week "+_vm._s(_vm.item.week.week)+" | "+_vm._s(_vm.item.topik ? _vm.item.topik.nama : "-")+" ")]),_c('p',{staticClass:"sub-topik"},[_vm._v(_vm._s(_vm.item.topik ? _vm.item.topik.subtopik : "-"))]),_c('div',{staticClass:"text-primary font-size-16 mt-auto d-flex flex-nowrap align-items-center"},[(_vm.item.tipe_assignment == 'File Upload')?_c('a-icon',{staticStyle:{"font-size":"20px"},attrs:{"type":"paper-clip"}}):(_vm.item.tipe_assignment == 'Essay')?_c('a-icon',{staticStyle:{"font-size":"20px"},attrs:{"type":"align-left"}}):(_vm.item.tipe_assignment == 'Multiple Upload')?_c('a-icon',{staticStyle:{"font-size":"20px"},attrs:{"type":"unordered-list"}}):_c('a-icon',{staticStyle:{"font-size":"20px","color":"gray"},attrs:{"type":"minus-circle"}}),_c('span',{staticClass:"ml-2",style:({ color: !_vm.item.tipe_assignment ? 'gray' : '' })},[_vm._v(_vm._s(!_vm.item.tipe_assignment ? "No Assignment" : _vm.item.tipe_assignment))])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }